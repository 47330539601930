// Modelled after
// www.joshwcomeau.com/css/make-beautiful-gradients/
// https://svbtle.com/
// https://dcurt.is/ipad-2020
// - The kudos animations

import { Controller } from "stimulus";
import Cookies from "js-cookie";
// import { useHover } from "stimulus-use";

export default class extends Controller {
  static targets = ["button", "count", "text"];
  static values = { postId: Number };

  connect() {
    this.sessionCount = 0;
    this.count();
    // This is if we want to do do anything with downvoting
    // if (Cookies.get("vote") !== undefined) {
    //   this.showUnlike();
    //   this.element.setAttribute("data-vote-id", Cookies.get("vote"));
    // }
  }

  // Was considering voting by hovering but not going to
  // work on this now.
  //
  // mouseEnter() {
  // if (Cookies.get("vote") !== undefined) {
  //   this.buttonTarget.classList.add("animate-down");
  // } else {
  //   this.buttonTarget.classList.add("animate-up");
  // }
  // this.timeout = setInterval(() => {
  //   if (Cookies.get("vote") !== undefined) {
  //     this.down();
  //   } else {
  //     this.up();
  //   }
  // }, 2500);
  // }

  // mouseLeave() {
  //   clearTimeout(this.timeout);
  //   this.buttonTarget.classList.remove("animate-up");
  //   this.buttonTarget.classList.remove("animate-down");
  // }

  up() {
    this.sessionCount = this.sessionCount + 1;
    // console.log(this.sessionCount);
    if (this.sessionCount < 3) {
      fetch(`/.netlify/functions/votes/${this.postIdValue}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          if (data.id !== undefined && data.id !== null) {
            this.countTarget.innerText =
              (parseInt(this.countTarget.innerText) || 0) + 1;
            this.animateVote();
            // Setting up downvote button
            // this.showUnlike();
            // Cookies.set("vote", data.id, {
            //   path: window.location.pathname,
            // });
            // this.element.setAttribute("data-vote-id", data.id);
          }
          return data;
        });
    }
  }

  down() {
    const voteId = document
      .querySelector("[data-vote-id]")
      .getAttribute("data-vote-id");
    fetch(`/.netlify/functions/votes/${voteId}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (data.error === undefined) {
          this.countTarget.innerText =
            (parseInt(this.countTarget.innerText) || 0) - 1;
        }
      })
      .then(() => {
        this.showLike();
        Cookies.remove("vote", { path: window.location.pathname });
      });
  }

  toggle() {
    this.buttonTarget.classList.remove("hidden");
    if (window.scrollY >= 200 && window.screen.width > 640) {
      this.buttonTarget.classList.add("opacity-100");
      this.buttonTarget.classList.remove("opacity-0");
    } else {
      this.buttonTarget.classList.add("opacity-0");
      this.buttonTarget.classList.remove("opacity-100");
    }
  }

  count() {
    fetch(`/.netlify/functions/votes/${this.postIdValue}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        return (this.countTarget.innerText = data.count);
      });
  }

  showUnlike() {
    this.textTarget.innerText = "Unlike";
    this.element.setAttribute(
      "data-action",
      "scroll@window->vote#toggle click->vote#down"
    );
  }

  showLike() {
    this.textTarget.innerText = "Like";
    this.element.setAttribute(
      "data-action",
      "scroll@window->vote#toggle click->vote#up"
    );
  }

  animateVote() {
    let animatedSpan = document.createElement("span");
    animatedSpan.classList.add(
      "text-gray-800",
      "font-bold",
      "absolute",
      "text-lg",
      "inset-0",
      "mt-0",
      "transform-all"
    );
    animatedSpan.innerText = "+1";
    this.element.appendChild(animatedSpan);
    setTimeout(() => {
      animatedSpan.classList.add("fade-out", "-translate-y-6");
    }, 300);
  }
}
