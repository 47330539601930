import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    let navItems = document.querySelectorAll(".gradient-tracking");
    navItems.forEach((navItem) => {
      navItem.addEventListener("mousemove", (e) => {
        let rect = navItem.getBoundingClientRect();
        let x = e.clientX - rect.left;
        let y = e.clientY - rect.top;
        navItem.style.setProperty("--x", x + "px");
        navItem.style.setProperty("--y", y + "px");
      });
    });
  }
}
