import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["cursor"];
  connect() {
    this.timeout;
    this.mousedown;
    this.overlink;

    this.setupAnchorListeners();

    document.onclick = () => {
      this.mousedown = true;
      this.cursorTarget.classList.add("click");
    };

    document.onmousedown = () => {
      this.mousedown = true;
      this.cursorTarget.classList.add("click");
    };

    document.onmouseup = () => {
      this.mousedown = false;
      this.cursorTarget.classList.remove("click");
    };
  }

  onMouseMove(e) {
    clearTimeout(this.timeout);
    this.cursorTarget.classList.remove("hidden", "idle");
    this.cursorTarget.classList.add("moving");
    this.cursorTarget.style.left = e.pageX + "px";
    this.cursorTarget.style.top = e.pageY + "px";
    // Use arrow function so "this" context is not lost
    // https://stackoverflow.com/questions/2130241/pass-correct-this-context-to-settimeout-callback
    this.timeout = setInterval(() => {
      if (!this.mousedown && !this.overlink) {
        this.cursorTarget.classList.remove("moving");
        this.cursorTarget.classList.add("idle");
      }
    }, 350);
  }

  onScroll(e) {
    this.cursorTarget.style.top = e.pageY + "px";
  }

  setupAnchorListeners() {
    document.querySelectorAll("a").forEach((element) => {
      element.onmouseenter = () => {
        this.overlink = true;
        this.cursorTarget.classList.add("link");
      };
      element.onmouseleave = () => {
        this.overlink = false;
        this.cursorTarget.classList.remove("link");
      };
    });

    document.querySelectorAll(".hover-block").forEach((element) => {
      element.onmouseenter = () => {
        this.overlink = true;
        this.cursorTarget.classList.add("focus");
      };
      element.onmouseleave = () => {
        this.overlink = false;
        this.cursorTarget.classList.remove("focus");
      };
    });
    // Could get the other specific elements and style them differently
    //  > a,p,span,code,pre,h1,h2,h3,h4,h5,h6
    document.querySelectorAll(".prose").forEach((element) => {
      element.onmouseenter = () => {
        this.overlink = true;
        this.cursorTarget.classList.add("prose-focus");
      };
      element.onmouseleave = () => {
        this.overlink = false;
        this.cursorTarget.classList.remove("prose-focus");
      };
    });
  }
}
