import { Controller } from "stimulus";
import Cookies from "js-cookie";

export default class extends Controller {
  connect() {
    if (Cookies.get("theme") === "light") {
    } else {
      if (
        localStorage.theme === "dark" ||
        (!("theme" in localStorage) &&
          window.matchMedia("(prefers-color-scheme: dark)").matches)
      ) {
        localStorage.setItem("theme", "dark");
        Cookies.set("theme", "dark");
        document.documentElement.classList.add("dark");
      } else {
        localStorage.setItem("theme", "light");
        Cookies.set("theme", "light");
        document.documentElement.classList.remove("dark");
      }
      localStorage.removeItem("theme");
    }

    let htmlTag = document.querySelector("html");
    if (htmlTag.classList.contains("dark")) {
      document.querySelector(".moon").classList.remove("hidden");
      document.querySelector(".sun").classList.add("hidden");
    } else {
      document.querySelector(".moon").classList.add("hidden");
      document.querySelector(".sun").classList.remove("hidden");
    }
  }

  toggle() {
    let htmlTag = document.querySelector("html");
    htmlTag.classList.toggle("dark");
    if (htmlTag.classList.contains("dark")) {
      document.querySelector(".moon").classList.remove("hidden");
      document.querySelector(".sun").classList.add("hidden");
      Cookies.set("theme", "dark");
    } else {
      document.querySelector(".moon").classList.add("hidden");
      document.querySelector(".sun").classList.remove("hidden");
      Cookies.set("theme", "light");
    }
  }
}
