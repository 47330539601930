import { Controller } from "stimulus";

export default class extends Controller {
  greet() {
    fetch("/.netlify/functions/hello")
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        if (data.message) {
          console.log(data.message);
        }
      });
  }
}
