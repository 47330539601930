import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["header", "content"];
  static values = {
    unfurled: Boolean,
  };

  connect() {
    if (!this.unfurledValue === true) {
      this.contentTarget.classList.add("hidden");
    }
  }

  toggle() {
    this.headerTarget.classList.toggle("blah");
    this.headerTarget.classList.toggle("blo");
    this.contentTarget.classList.toggle("hidden");
  }
}
