import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["menu", "toggle"];

  toggle(e) {
    this.open = !this.open;
    this.updateMenu();
  }

  updateMenu() {
    if (this.open) {
      this.menuTarget.style.display = "block";
      document.querySelector("body").style.overflow = "hidden";
    } else {
      this.menuTarget.style.display = "none";
      document.querySelector("body").style.overflow = "auto";
    }
  }
}
